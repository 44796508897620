import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../containers/Home/Layout";
import { interiorTheme } from "common/theme/interior";
import "common/assets/css/main-page.css";

import Seo from "components/seo";

import TitleBar2 from "common/components/Titlebar2";
import SingleServicePage from "../containers/Home/pages.style";
import ServiceSectionTwo from "../containers/Sections/ServiceSectionTwo";
import ServiceSectionThree from "../containers/Sections/ServiceSectionThree";
import ServiceSectionOne from "../containers/Sections/ServiceSectionOne";
import ServiceSectionFour from "../containers/Sections/ServiceSectionFour";

const HairFixingForMen = () => {

  const seoData = {
    title: 'Hair Fixing in Delhi - Radiance Hair Studio',
    description: 'Facing hair problems? We are here to serve you with the most advanced hair fixing methods in Delhi by certified experts at Radiance Hair Studio.',
    keywords: ['Hair Fixing in Delhi'],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "@id": "Service",
    "alternateName": "Radiance Hair Studio",
    "areaServed": "New Delhi",
    "image": [
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/overlay7.png",
      "https://www.radiancehairstudio.com/service-images/Hair-Fixing-for-Men's.jpg",
      "https://www.radiancehairstudio.com/hair-fixing1.png",
      "https://www.radiancehairstudio.com/hair-fixing2.png",
      "https://www.radiancehairstudio.com/help-image-1.png"
    ],
    "logo": "https://www.radiancehairstudio.com/static/radiance-logo-721b0cd4def3d400c04ba899afd38810.png",
    "additionalType": "https://www.radiancehairstudio.com/hair-fixing.php",
    "category": "https://www.radiancehairstudio.com/hair-fixing.php",
    "description": "Facing hair problems? We are here to serve you with the most advanced hair fixing methods in Delhi by certified experts at Radiance Hair Studio.",
    "disambiguatingDescription": "Hair Fixing in Delhi",
    "serviceType": "Hair Fixing for Men's"
  };
  
  const sectionS1 = [
    {
      title: "Hair Patch for Men",
      img: "/hair-patch-for-men.png",
    },
    {
      title: "Human Hair Extension",
      img: "/7.png",
    },
    {
      title: "Hair Weaving",
      img: "/8.png",
    },
    {
      title: "Non-Surgical Hair Replacement",
      img: "/9.png  ",
    }
  ];

  const sectionS2 = [
    {
      img: "/service-images/Hair-Fixing-for-Men's.jpg",
      content: (
        <div>
          <h2>Hair Fixing in Delhi- Radiance Hair Studio</h2>
          <p>
          Baldness, extreme hair fall and hair fall at some parts of your scalp – these three
          are the major reasons to undergo hair loss treatment. You primarily focus on the many kinds of hair treatments here, but you also need to consider the techniques used for hair repair in Delhi.
          </p>
          <h2>Radiance Fixes Everything</h2>
          <p>
          Radiance Hair Studio offers the best <a href="/non-surgical-hair-replacement-for-men.php" style={{ color: "#373737"}}>Non-Surgical Hair replacement</a> services thanks to its highly qualified staff. Experience is quite there with us. In addition, we are fully outfitted with the newest methods in this specific fashion industry.
          </p>
          <p>
          There are three key methods to fix your hair externally – using adhesive, using tapes and clips. Each of these types of hair fixation needs special skills and special know-how. All repairing procedures are expertly handled by the professionals at Radiance, and each one is applied to the clients with the utmost care. The products we use in hair fixing like clips, glues, and tapes are completely safe and certified by medical science. Moreover, here you don’t need to get worried about artificial synthetic hair extensions. Because at our hair studio, extensions are made of 100% natural human hair and can also be customized frequently as per your own preference. So, when you choose us for the purpose of hair fixing in Delhi, there is absolutely nothing to be worried about. 
          </p>
          <p>
          Try to get through the benefits of these fixing processes and some related queries to equip yourself with better knowledge. 
          </p>
        </div>
      )
    }
  ];
  
  const sectionS3 = [
    {
      smallImg: "/hair-fixing1.png",
      largeImg: "/hair-fixing2.png",
      content: (
        <div>
          <h2>Advantages of Hair Fixing in Delhi</h2>
          <p></p>
          <ul >
            <li>
            One of the biggest benefits of <strong>Non-Surgical Hair replacement</strong> is that it is a completely external process. Unlike surgical treatments, this method is painless and does not involve any medical procedures, allowing you to achieve a transformed look without any discomfort.
               </li>
            <li>
            Another major advantage is the <strong>natural appearance</strong> it provides. Using high-quality human hair, hair fixing ensures that your new look blends seamlessly with your existing hair. Friends and family will be amazed at how natural and realistic it appears.
            </li>
            <li>
            Since the process is external, there are <strong>no side effects</strong> to worry about. The applied hair—whether natural or synthetic—may experience wear over time, so periodic replacements are necessary to maintain the best look.
            </li>
            <li>
            A key benefit of hair fixing is its <strong>versatility</strong>. You can experiment with different hairstyles whenever you want. As the attachments can be removed and reinstalled with ease, you have the freedom to change your style based on your preference.
            </li>
            <li>If you're looking for a hassle-free way to restore your hair and confidence, hair fixing in Delhi is an excellent solution!</li>
          </ul>
        </div>
      )
    }
  ];
  
  const sectionS4 = [
    {
      title: "FAQs for Hair Fixing",
      description: "You still might have some confusion about hair fixing and some quires are possibly roaming around your mind. Not an issue. Here we tried to answer some questions often asked by customers.",
      description2: "",
      faq: [
        {
          title: "Will the treatment cause any discomfort?",
          description: "The hair extension treatments, where you undergo surgery are painful, but that is not true in this case.Since the entire procedure at Radiance is external, there is no discomfort involved. Nothing more than waiting for the adhesive glue to dry out will be required of you."
        },
        {
          title: "What is the lifespan of hair fixing?",
          description: "The durability of hair fixing depends on the method used. Adhesive-based applications typically last 3-4 weeks, while tape-based solutions can last 6-8 weeks with proper care. For clip-based hair fixing, longevity depends on how well you maintain and handle the clips."
        },
        {
          title: "Which hair fixing method is the safest?",
          description: "All the methods used in hair fixing are safe. Adhesives and tapes are dermatologically tested and medically approved, ensuring they do not cause harm to the scalp."
        },
        {
          title: "Will my hair look natural?",
          description: "At Radiance, we use only 100% natural hair for hair fixing in Delhi. This ensures a completely natural appearance. Even with pasting methods, the extensions blend seamlessly with your scalp, making them virtually undetectable."
        }
      ]
    }
  ];

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <TitleBar2 title="Hair Fixing for Men's" banner="/hair-fixing-for-men-banner.png"/>
          <SingleServicePage>
            <ServiceSectionOne data={sectionS1} title="Hair Fixing in Delhi- Radiance Hair Studio" />
            <ServiceSectionTwo data={sectionS2} />
            <ServiceSectionThree data={sectionS3} />
            <ServiceSectionFour data={sectionS4} />
          </SingleServicePage>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default HairFixingForMen;
